import React, { useState } from "react";
import logo from "../images/Logo-Color.png";
import SocialNetwork from "../components/UI/SocialNetwork";
const NavBar = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 91.15) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <header id="#main" className={navbar ? "active" : navbar}>
      <div className="container mx-auto flex flex-wrap p-1 lg:p-3 flex-col md:flex-row items-center mb-2">
        <a
          href="/"
          className="flex title-font font-medium items-center mb-4 md:mb-0"
        >
          <img className="lg:w-16 w-10" src={logo} alt="ark-logo" />
        </a>
        <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center lg:pr-20">
          <a
            href="#about"
            className="text-lg mx-2 lg:mx-4 hover:text-indigo-600 transition duration-500 arksoft-text-color font-medium"
          >
            Nosotros
          </a>
          <a
            href="#knowHow"
            className="text-lg mx-2 lg:mx-4 hover:text-indigo-600 transition duration-500 arksoft-text-color font-medium"
          >
            Que hacemos
          </a>
          <a
            href="#staff"
            className="text-lg mx-2 lg:mx-4 hover:text-indigo-600 transition duration-500 arksoft-text-color font-medium"
          >
            Equipo
          </a>
          <a
            href="#contact"
            className="hidden text-lg lg:flex md:flex sm:flex lg:mx-4 mt-4 lg:mt-0 md:mt-0 sm:mt-0 hover:text-indigo-600 transition duration-500 arksoft-text-color font-medium"
          >
            Contacto
          </a>
        </nav>
        <div className="hidden lg:flex md:flex sm:flex">
          <SocialNetwork />
        </div>
      </div>
    </header>
  );
};

export default NavBar;
