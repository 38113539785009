import React from "react";

const SocialNetwork = () => {
  return (
    <span className="flex">
      <a
        href="https://www.linkedin.com/company/arksoftsolutionsarg/about/"
        className="text-gray-500"
        target="blank"
      >
        <svg
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0}
          className="arksoft-text-color w-5 h-5 transition duration-500 hover:text-indigo-600"
          viewBox="0 0 24 24"
        >
          <path
            stroke="none"
            d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
          />
          <circle cx={4} cy={4} r={2} stroke="none" />
        </svg>
      </a>

      <a
        href="https://www.instagram.com/arksoft.solutions/"
        className="ml-3 text-gray-500"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="arksoft-text-color w-5 h-5 transition duration-500 hover:text-indigo-600"
          viewBox="0 0 24 24"
        >
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
        </svg>
      </a>
    </span>
  );
};

export default SocialNetwork;
