import React from "react";

const OurServices = () => {
  return (
    <section className="text-gray-600 body-font" id="knowHow">
      <div className="container px-5 py-24 mx-auto border-b">
        <div className="flex flex-col text-center w-full mb-16 ">
          <h1 className="sm:text-3xl text-2xl font-medium title-font arksoft-text-color">
            Que Hacemos
          </h1>
        </div>
        <div className=" text-center flex flex-wrap justify-center">
          <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-indigo-800 font-medium title-font mb-2">
              Web Development
            </h2>
            <p className="leading-relaxed text-base mb-4">
              Ofrecemos desarrollos con las tecnologías más cotizadas del
              mercado, como React y Node.
            </p>
          </div>
          <div className="text-center xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-indigo-800 font-medium title-font mb-2">
              Mobile Development
            </h2>
            <p className="leading-relaxed text-base mb-4">
              Somos especialistas en desarrollo mobile híbrido empleando
              tecnologías de punta como Flutter y React Native.
            </p>
          </div>
          <div className="text-center xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 lg:border-r-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-indigo-800 font-medium title-font mb-2 ">
              Database Services
            </h2>
            <p className="leading-relaxed text-base mb-4">
              Administramos, diseñamos y desarrollamos proyectos de bases de
              datos de diversos proveedores de alta criticidad.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
