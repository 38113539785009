import React from "react";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import Footer from "./Footer";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = () =>
    toast("Su consulta fue enviada.", {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const onSubmit = (data) => {
    console.log(data);

    emailjs
      .sendForm(
        "service_g6jlgxz",
        "template_oympop2",
        "#contact-form",
        "user_u3JCFDjEqeH3RK8RovLV3"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.assign("https://www.arksoft.com.ar/");
        },
        (error) => {
          console.log(error.text);
        }
      );
    notify();
  };

  return (
    <section className="text-gray-600 body-font absolute w-full" id="contact">
      <div className="container px-5 py-20 mx-auto ">
        <div className="flex flex-col text-center w-full mb-12 ">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 arksoft-text-color">
            Contactanos
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-md">
            Ante cualquier consulta sobre el alcance de nuestros servicios, no
            dudes en escribirnos y un representante de nuestro equipo se
            contactará a la brevedad.
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <form
            id="contact-form"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-wrap -m-2"
          >
            <div className="p-2 w-1/4">
              <div className="relative">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600"
                >
                  Nombre
                </label>
                <input
                  {...register("name", {
                    required: true,
                  })}
                  type="text"
                  id="name"
                  name="name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.name && "Nombre Incorrecto."}
              </div>
            </div>
            <div className="p-2 w-1/4">
              <div className="relative">
                <label
                  htmlFor="phone"
                  className="leading-7 text-sm text-gray-600"
                >
                  Teléfono
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.email && "El Email es incorrecto."}
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-600"
                >
                  Mensaje
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                />
                {errors.message && "El mensaje se encuentra vacío."}
              </div>
            </div>
            <div className="p-2 w-full">
              <input
                type="submit"
                value="Enviar"
                className="flex mx-auto text-white arksoft-bg-color transition duration-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              />
              <ToastContainer
                position="bottom-center"
                autoClose={6000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <p className="leading-normal my-1">Caba, Buenos Aires, 1430</p>
              <div className="py-2 flex justify-center text-center ">
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="arksoft-text-color w-10 h-10 transition duration-500 hover:text-indigo-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      className=""
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default ContactUs;
