import React from "react";

const AboutUs = () => {
  return (
    <section className="text-gray-600 body-font text-center" id="about">
      <div className="justify-center container px-5 py-28 mx-auto flex flex-wrap border-b border-t">
        <div className="flex flex-col text-center w-full mb-4">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-1 arksoft-text-color">
            Nosotros
          </h1>
        </div>
        <div className="md:w-3/5 md:pl-6">
          <p className="leading-relaxed text-base">
            Arksoft Solutions es una empresa dedicada a brindar servicios de
            administración, desarrollo y soporte de aplicaciones web y mobile
            como también de base de datos, utilizando tecnología de punta y
            logrando los mejores indicadores de disponibilidad.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
