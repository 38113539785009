import React from "react";
import sebastian_lopez from "../images/sebastian_lopez.jpeg";
import facundo_debuono from "../images/facundo_debuono.jpeg";
import tomas_cabral from "../images/tomas_cabral.jpg";
import santiago_calvi from "../images/santiago_calvi.jpeg";
import jorge_rodriguez from "../images/jorge_rodriguez.jfif";
import marcos_romero from "../images/marcos_romero.jfif";
import ezequiel_medina from "../images/ezequiel_medina.jpeg";

const EmployeeCardSection = () => {
  return (
    <section className="text-gray-600 body-font" id="staff">
      <div className="container px-5 py-24 mx-auto border-b">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 arksoft-text-color">
            Nuestro Equipo
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Está compuesto por profesionales con vasta trayectoria en el rubro y
            experiencia en la administración, el soporte de aplicaciones y de
            base de datos de gran porte, tunning de performance, desarrollo y
            consultoría.{" "}
          </p>
        </div>
        <div className="flex flex-wrap -m-2">
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={jorge_rodriguez}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Jorge Rodriguez
                </h2>
                <p className="text-gray-500">Owner and Database Specialist</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={tomas_cabral}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Tomas Cabral Hunter
                </h2>
                <p className="text-gray-500">Full-Stack Developer</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={sebastian_lopez}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Sebastián Lopez
                </h2>
                <p className="text-gray-500">Front-End Developer</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={santiago_calvi}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Santiago Calvi
                </h2>
                <p className="text-gray-500">Mobile Team Lead</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={facundo_debuono}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Facundo De Buono
                </h2>
                <p className="text-gray-500">Mobile Developer</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={marcos_romero}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Marcos Romero
                </h2>
                <p className="text-gray-500">
                  Cloud Architect and Backend Developer
                </p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={ezequiel_medina}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Ezequiel Medina
                </h2>
                <p className="text-gray-500">UX/UI Designer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeeCardSection;
