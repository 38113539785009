import React from "react";
import SocialNetwork from "./UI/SocialNetwork";

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font ">
      <div className="flex flex-wrap md:text-left order-first">
        {/* <div className="text-center lg:w-1/3 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
            CATEGORIES
          </h2>
          <nav className="list-none mb-10">
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                First Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Second Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Third Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Fourth Link
              </a>
            </li>
          </nav>
        </div>
        <div className="text-center lg:w-1/3 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
            CATEGORIES
          </h2>
          <nav className="list-none mb-10">
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                First Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Second Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Third Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Fourth Link
              </a>
            </li>
          </nav>
        </div>
        <div className="text-center lg:w-1/3 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
            CATEGORIES
          </h2>
          <nav className="list-none mb-10">
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                First Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Second Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Third Link
              </a>
            </li>
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-800">
                Fourth Link
              </a>
            </li>
          </nav>
        </div> */}
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row items-center">
          <p className="text-gray-500 text-sm text-center  sm:text-left">
            © 2021 Arksoft Solutions —
            <p className="text-gray-600 ml-1" target="_blank">
              Todos los derechos reservados.
            </p>
          </p>
          <div className="py-4 px-5 inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <SocialNetwork />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
